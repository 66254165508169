import React from 'react';
import './autorepairs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faWrench, faTachometerAlt, faOilCan,
    faCarBattery, faRoad,
    faCogs, faCar, faThermometerHalf,
    faGasPump, faBolt, faBatteryHalf,
    faCheckCircle, faExclamationTriangle, faSnowflake, faFire, faPhoneAlt,
    faFan, faChargingStation, faTools, faToolbox
    // Removed the non-existing or duplicate icons
} from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

function AutoRepairs() {
    const services = [
        { name: "Axle, CV Joint & Driveshaft Repair", icon: faCogs }, // Cogs for mechanical parts
        { name: "Belts & Hoses", icon: faTools }, // Tools for general repair work
        { name: "Brake Repair", icon: faBolt }, // Bolt for the strength of brakes
        { name: "Climate Control Systems", icon: faFan }, // Fan for air circulation
        { name: "Cooling System Repair", icon: faThermometerHalf }, // Thermometer for temperature monitoring
        { name: "Diesel Engine Repair", icon: faGasPump }, // Gas pump for fuel-related issues
        { name: "Differential Repair", icon: faCarBattery }, // Car battery for electrical systems
        { name: "Electrical and Electronic Systems", icon: faChargingStation }, // Charging station for electrical systems
        { name: "Engine Diagnostics and Performance", icon: faCheckCircle }, // Check circle for diagnostics
        { name: "Exhaust System Repair", icon: faFire }, // Fire for combustion and exhaust
        { name: "Four Wheel Drive System", icon: faRoad }, // Road for the drive system
        { name: "Lube, Oil & Filter Change", icon: faOilCan }, // Oil can for lubrication
        { name: "Preventive Maintenance", icon: faCar }, // Car icon for overall maintenance
        { name: "Roadside Assistance", icon: faExclamationTriangle }, // Triangle for emergency
        { name: "Steering and Suspension System", icon: faWrench }, // Wrench for mechanical repair
        { name: "Tire Pressure Monitoring System (TPMS)", icon: faTachometerAlt }, // Tachometer for pressure monitoring
        { name: "Tire Services", icon: faBatteryHalf }, // Battery half to symbolize maintenance
        { name: "Tire Alignment", icon: faSnowflake }, // Snowflake for precision
        { name: "Transmission Repair", icon: faCarBattery } // Car battery for power transmission
    ];
    return (
        <>
            <Helmet>
                <title>Expert Auto Repair Services - Goldwrench Automotive</title>
                <meta name="description" content="Goldwrench Automotive offers professional auto repair services including axle, brake, and engine diagnostics, ensuring top performance for your vehicle." />
                <meta name="keywords" content="auto repair, brake repair, engine diagnostics, axle repair, car maintenance, Goldwrench automotive services" />
            </Helmet>
            <div className="autorepair-intro">
                <h2><FontAwesomeIcon icon = {faTools}/> Goldwrench Auto Repair Services</h2>
                <p>Explore our wide range of automotive repair services. Our expert team is ready to assist you with all your vehicle needs.</p>
                <div className="call-now">
                    <FontAwesomeIcon icon={faPhoneAlt} className="call-icon" />
                    <a href="tel:+15409496835">
                        <h2>Call Now: (540) 949-6835</h2>
                    </a>
                </div>
            </div>
            <div className="autorepair-areas">
                {/* Areas Served Content */}
            </div>
            <div className="autorepairs-container">
                <h2><FontAwesomeIcon icon = {faToolbox}/> Repair Services</h2>
                <ul>
                    {services.map((service, index) => (
                        <li key={index}>
                            <FontAwesomeIcon icon={service.icon} />
                            {service.name}
                        </li>
                    ))}
                </ul>
            </div>

        </>
    );
}

export default AutoRepairs;
