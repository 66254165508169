import React from 'react';
import './BGProducts.scss';
import '../components/business-info.scss';
import { Helmet } from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBolt, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

const BGProducts = () => {
    return (
        <>
        <div className="bg-products business-info">
            <Helmet>
                <title>BG Automotive Products - Goldwrench Services</title>
                <meta name="description"
                      content="Goldwrench Automotive offers top-notch BG Automotive Products in Waynesboro, Crimora, Stuarts Draft, Fishersville, and nearby areas. Enhance your vehicle with our BG services."/>
                <meta name="keywords"
                      content="BG automotive products, vehicle care, Goldwrench services, car efficiency, automotive health, BG maintenance, BG vehicle service"/>
            </Helmet>

            <h2><FontAwesomeIcon icon={faBolt}/> About BG Products</h2>
            <p>
                A leader in automotive solutions, BG Products, Inc. offers a variety of high-quality products designed
                for cars, industrial machines, and heavy-duty equipment. Available through trained professionals at
                select distributors, BG Products are synonymous with quality and endurance.
            </p>
            <p>
                Opt for BG Products to boost your vehicle's performance and lifespan. Visit us for top-tier services
                using BG Products, and consider adding them to your regular maintenance routine for improved fuel
                efficiency, reduced emissions, and a longer-lasting vehicle.
            </p>
            </div>
        <div className="business-info">
            <h2><FontAwesomeIcon icon={faMapMarkerAlt}/> Goldwrench Automotive service locations</h2>
            <p>
                Serving Waynesboro, Crimora, Stuarts Draft, Fishersville, and nearby communities, Goldwrench Automotive
                is dedicated to providing outstanding BG Fluid and Fuel Services. Our selection of BG Products is second
                to none in these regions, catering to your vehicle's every need.
            </p>
        </div>
<div className={"bg-products"}>

            <h3>Services Offered</h3>
            <ul className="services-list">
                <li>BG 44K Power Enhancer</li>
                <li>BG Basic Oil Change</li>
                <li>BG Performance Oil Change</li>
                <li>BG Brake Service</li>
                <li>BG Battery Service</li>
                <li>BG Climate Control Service</li>
                <li>BG Cooling System Service</li>
                <li>BG Driveline Service</li>
                <li>BG Fuel/Air Induction</li>
                <li>BG Fuel/Air Induction Service</li>
                <li>BG Lifetime Protection Plan</li>
                <li>BG Power Steering Service</li>
                <li>BG Transmission Service</li>
                <li>BG Diesel Induction Service</li>
                <li>BG Diesel Injection Service</li>
            </ul>
        </div>
    </>
    );
};

export default BGProducts;
