import React from 'react';
import './about.scss';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";

function About() {
    return (
        <div className="about-container">
            <Helmet>
                <title>About Goldwrench Automotive - Professional Auto Services</title>
                <meta name="description" content="Learn about Goldwrench Automotive, your trusted family-owned auto service provider since 2007. Our ASE-certified technicians are committed to excellent service and customer satisfaction." />
                <meta name="keywords" content="Goldwrench Automotive, auto repair, ASE-certified technicians, NAPA AutoCare Center, auto services, family-owned business, car maintenance" />
            </Helmet>

            {/* Vision Card */}
            <section className="vision">
                <h2>Vision</h2>
                <div className="vision-content">
                    <h3>TO BE #1 IN THE UNIVERSE</h3>
                    <p>If There’s Cars on Mars, We’ll Be There to Fix Them</p>
                </div>
            </section>
           {/* About Us Card */}
           <section className="about-us">
                <h2><FontAwesomeIcon icon={faCar} /> About Goldwrench Automotive</h2>
                <p>We are an independent, family-owned business that opened our doors in 2007 and have been serving our community with pride ever since. Our mission is to ensure that you receive the best auto repair and diagnostic services possible.</p>
                <p>Our team consists of ASE-certified technicians, led by an ASE-Master Mechanic technician. We work on both light diesel and passenger vehicles, using high-quality parts to provide excellent service every time.</p>
                <p>We appreciate your patronage and believe in integrity and honesty. Our staff is here to answer any questions and ensure your experience is a positive one. Our goal is to keep you moving through the years with a reliable vehicle.</p>
                <p>As a NAPA AutoCare Center, we back qualifying work through a nationwide warranty. We are dedicated to providing the best workmanship and customer experience possible.</p>
                <p>Reasons to choose Goldwrench Automotive include automotive repairs, nationwide warranty, preventive maintenance, and more. Visit us for all your auto repair needs.</p>
            </section>
            {/* Mission & Values Card */}
            <section className="mission-values">
                <h2>Mission & Values</h2>
                <div className="mission">
                    <h3>Mission</h3>
                    <p>Set the Standard…The GOLD Standard</p>
                    <p>Improve Each Day</p>
                    <p>Have Some Fun</p>
                </div>
                <div className="values">
                    <h3>Values</h3>
                    
                        <p>Create Raving Fans</p>
                        <p>Be Honest, Show Respect, and Have Integrity</p>
                        <p>Monitor Results, Not Activities</p>
                        <p>Mistakes are a Wise Person’s Education</p>
                        <p>Challenge Ourselves, Challenge Each Other, Break Boundaries</p>
                        <p>Give Loyalty – Get Loyalty</p>
                        <p>Communication, Communication, Communication</p>
                        <p>Attitude Is a Matter of Choice - Make the Right Choice</p>
                        <p>Contribute to the Team</p>
                        <p>Enjoy the Journey - Do It With Pride!</p>
                    
                </div>
            </section>

 
        </div>
    );
}

export default About;
