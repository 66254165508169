import React from "react"
import "./terms.scss"
const Terms = () => {
        return (
            <div className="terms-of-use">
                <h1>Terms of Use for Goldwrench Automotive</h1>
                <p>Last Updated: 01/22/2024</p>

                <section>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing and using the website of Goldwrench Automotive ("we", "us", or "our"), you agree to
                        be bound by these Terms of Use ("Terms"). These Terms apply to all visitors, users, and others
                        who access or use the Service. Your access to and use of the Service is conditioned on your
                        acceptance of and compliance with these Terms. If you disagree with any part of the terms, then
                        you may not access the Service.</p>
                </section>

                <section>
                    <h2>2. Use License</h2>
                    <p>Permission is granted to temporarily download one copy of the materials (information or software)
                        on Goldwrench Automotive's website for personal, non-commercial transitory viewing only. This is
                        the grant of a license, not a transfer of title, and under this license you may not:</p>
                    <ul>
                        <li>Modify or copy the materials;</li>
                        <li>Use the materials for any commercial purpose, or for any public display (commercial or
                            non-commercial);
                        </li>
                        <li>Attempt to decompile or reverse engineer any software contained on Goldwrench Automotive's
                            website;
                        </li>
                        <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
                    </ul>
                    <p>This license shall automatically terminate if you violate any of these restrictions and may be
                        terminated by Goldwrench Automotive at any time.</p>
                </section>

                <section>
                    <h2>3. Disclaimer</h2>
                    <p>The materials on Goldwrench Automotive's website are provided on an 'as is' basis. Goldwrench
                        Automotive makes no warranties, expressed or implied, and hereby disclaims and negates all other
                        warranties including, without limitation, implied warranties or conditions of merchantability,
                        fitness for a particular purpose, or non-infringement of intellectual property or other
                        violation of rights.</p>
                </section>

                <section>
                    <h2>4. Limitations</h2>
                    <p>In no event shall Goldwrench Automotive or its suppliers be liable for any damages (including,
                        without limitation, damages for loss of data or profit, or due to business interruption) arising
                        out of the use or inability to use the materials on Goldwrench Automotive's website, even if
                        Goldwrench Automotive or a Goldwrench Automotive authorized representative has been notified
                        orally or in writing of the possibility of such damage.</p>
                </section>

                <section>
                    <h2>5. User Conduct and Obligations</h2>
                    <p>You agree to follow all applicable laws and regulations when using this website. You are solely
                        responsible for the content of your communications through the website. You agree not to:</p>
                    <ul>
                        <li>Upload or distribute any files that contain viruses, corrupted files, or any other similar
                            software or programs that may damage the operation of another's computer;
                        </li>
                        <li>Interfere with or disrupt networks or websites connected to the website;</li>
                        <li>Violate any applicable laws or regulations, including privacy laws;</li>
                        <li>Engage in any activity that would constitute a criminal offense or give rise to a civil
                            liability;
                        </li>
                    </ul>
                </section>
                <section>
                    <h2>6. Intellectual Property Rights</h2>
                    <p>All content on this website, including but not limited to text, graphics, logos, and software, is
                        the property of Goldwrench Automotive or its content suppliers and is protected by intellectual
                        property laws. Unauthorized use of the content may violate copyright, trademark, and other
                        laws.</p>
                </section>
                <section>
                    <h2>7. Indemnification</h2>
                    <p>You agree to indemnify, defend, and hold harmless Goldwrench Automotive, its officers, directors,
                        employees, agents, licensors, suppliers, and any third-party information providers to the
                        website from and against all losses, expenses, damages, and costs, including reasonable
                        attorneys' fees, resulting from any violation of these Terms or any activity related to your
                        account (including negligent or wrongful conduct) by you or any other person accessing the
                        website using your service.</p>
                </section>
                <section>
                    <h2>8. Modifications to Service</h2>
                    <p>Goldwrench Automotive reserves the right at any time and from time to time to modify or
                        discontinue, temporarily or permanently, the Service (or any part thereof) with or without
                        notice. You agree that Goldwrench Automotive shall not be liable to you or to any third party
                        for any modification, suspension, or discontinuance of the Service.</p>
                </section>
                <section>
                    <h2>9. Termination of Use</h2>
                    <p>Goldwrench Automotive may, in its sole discretion, terminate or suspend your access to all or
                        part of the website and the Service for any reason, including, without limitation, breach of
                        these Terms.</p>
                </section>
                <section>
                    <h2>10. Governing Law</h2>
                    <p>These Terms shall be governed and construed in accordance with the laws of Virginia, United
                        States
                        without regard to its conflict of law provisions. Our failure to enforce any right or provision
                        of these Terms will not be considered a waiver of those rights.</p>
                </section>
                <section>
                    <h2>11. Links to Third-Party Websites</h2>
                    <p>Our website may contain links to third-party web sites or services that are not owned or
                        controlled by Goldwrench Automotive. Goldwrench Automotive has no control over, and assumes no
                        responsibility for, the content, privacy policies, or practices of any third-party web sites or
                        services. You further acknowledge and agree that Goldwrench Automotive shall not be responsible
                        or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or
                        in connection with the use of or reliance on any such content, goods, or services available on
                        or through any such web sites or services.</p>
                </section>
                <section>
                    <h2>12. No Unlawful or Prohibited Use</h2>
                    <p>As a condition of your use of this website, you warrant to Goldwrench Automotive that you will
                        not use the website for any purpose that is unlawful or prohibited by these terms, conditions,
                        and notices. You may not use the website in any manner that could damage, disable, overburden,
                        or impair the website or interfere with any other party's use and enjoyment of the website.</p>
                </section>
                <section>
                    <h2>13. International Use</h2>
                    <p>Goldwrench Automotive makes no representation that materials on this website are appropriate or
                        available for use in locations outside the United States, and accessing them from territories
                        where their contents are illegal is prohibited. Those who choose to access this site from other
                        locations do so on their own initiative and are responsible for compliance with local laws.</p>
                </section>
                <section>
                <h2>14. Children's Online Privacy Protection</h2>
                <p>
                    In compliance with the Children's Online Privacy Protection Act (COPPA), Goldwrench Automotive will not knowingly provide our service to persons under the age of 13. If you are under the age of 13, you may not use the website. If you become aware that a child has provided us with personal information, please contact us. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
                </p>
            </section>
                <section>
                    <h2>15. Changes to Terms</h2>
                    <p>Goldwrench Automotive reserves the right, in its sole discretion, to change the Terms under which
                        the website is offered. The most current version of the Terms will supersede all previous
                        versions. Goldwrench Automotive encourages you to periodically review the Terms to stay informed
                        of our updates.</p>
                </section>
            </div>
        );
};

export default Terms;
