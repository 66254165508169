import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faShieldAlt, faCreditCard, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import './NAPAAutoCare.scss';
import '../components/business-info.scss';
import { Helmet } from "react-helmet";


const NAPAAutoCare = () => {
    return (
        <div className="napa-auto-care business-info">
            <Helmet>
                <title>NAPA AutoCare Center Benefits - Goldwrench Automotive</title>
                <meta name="description" content="Explore the benefits of choosing Goldwrench Automotive, a NAPA AutoCare Center. We provide certified technicians, nationwide warranty, and quality services for your vehicle." />
                <meta name="keywords" content="NAPA AutoCare, ASE-certified technicians, automotive warranty, quality auto service, Goldwrench benefits" />
            </Helmet>

            <h2><FontAwesomeIcon icon={faCar} /> NAPA AutoCare Center Advantages</h2>

            <div>
                <br></br>
            </div>
            <h3><FontAwesomeIcon icon={faShieldAlt} /> Expert Technicians</h3>
            <p>
                Our ASE-certified technicians bring expert knowledge and skills, ensuring top-notch service for every vehicle.
            </p>

            <h3><FontAwesomeIcon icon={faCreditCard} /> Nationwide Peace of Mind Warranty</h3>
            <p>
                Enjoy a stress-free experience with our nationwide warranty, honored across over 14,000 locations.
            </p>

            <h3><FontAwesomeIcon icon={faThumbsUp} /> Flexible Financing Options</h3>
            <p>
                With NAPA EasyPay, financing your automotive services and purchases is simpler and more accessible.
            </p>

            <h3><FontAwesomeIcon icon={faCar} /> Guaranteed Quality Service</h3>
            <p>
                Rely on us for consistently high-quality service, backed by the trusted NAPA name, wherever you are.
            </p>

            <p className="contact-info">
                Get in touch with Goldwrench Automotive for all the NAPA AutoCare Center benefits.
            </p>
        </div>
    );
};

export default NAPAAutoCare;
