import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/home'
import About from './pages/about'
import AutoRepair from './pages/autorepairs'
import 'bootstrap/dist/css/bootstrap.min.css';

import TowingService from "./pages/towingservice";
import Contact from "./pages/contact";
import {ScrollToTop} from "./components/ScrollToTop";
import HeaderBar from "./components/HeaderBar";
import BGProducts from "./pages/bgproducts";
import NAPAAutoCare from "./pages/napaautocare";
import TireServices from "./pages/tireservices";
import Terms from "./pages/terms";
import StateInspection from "./pages/stateinspection";
import Footer from "./components/footer";
import PrivacyPolicy from "./pages/privacypolicy";


const App = () => {
    return (

        <Router>
            <ScrollToTop /> {/* Include the ScrollToTop component */}
            <HeaderBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/autorepairs" element={<AutoRepair />} />
                <Route path="/towingservice" element={<TowingService />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/bgproducts" element={<BGProducts/>} />
                <Route path = "/napa" element={<NAPAAutoCare />} />
                <Route path = "/tires" element={<TireServices />} />
                <Route path = "/terms" element={<Terms />} />
                <Route path = "/privacy-policy" element={<PrivacyPolicy />} />
                <Route path = "/stateinspection" element={<StateInspection />} />
            </Routes>
            <Footer/>

        </Router>
    );
};

export default App;
