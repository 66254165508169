import React from 'react';
import './TireServices.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDotCircle, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet"; // This is the CSS file for styling

const TireServices = () => {
    return (
        <div className="tire-services">
            <Helmet>
                <title>Tire Selection and Assistance - Goldwrench Automotive</title>
                <meta name="description" content="Choose the right tires for your vehicle with Goldwrench Automotive. Offering a wide range of tire options and expert advice for optimal performance and safety." />
                <meta name="keywords" content="tire services, vehicle tires, tire selection, Goldwrench Automotive, tire assistance, car tires" />
            </Helmet>
            <h2><FontAwesomeIcon icon={faDotCircle}/> Find Your Perfect Tires</h2>
            <p>
                Looking for the right tires for your vehicle? We've got you covered. With a wide range of options and
                expert advice, we can help you find exactly what you need.
            </p>
            <div className="call-now">
                <FontAwesomeIcon icon={faPhoneAlt} className="call-icon"/>
                <a href="tel:+15409496835">
                    <h2>Call Now: (540) 949-6835</h2>
                </a>
            </div>
            <p>
                <strong>Quick Tire Assistance:</strong> A quick phone call is all it takes. Our experts are ready to
                help you select the best tires for your vehicle and driving needs. Call us now!
            </p>

        </div>
    );
};

export default TireServices;
